/* Light mode styles */

.ej-light-fn-sup {
  display: inline-flex !important;
  align-items: center;
  justify-content: center;
  margin: 0 7px 0 5px;
  padding: 3px 6px;
  font-size: 12px;
  line-height: 12px;
  font-weight: 600;
  cursor: pointer;
  /* textPrimary */
  color: #000;
  /* background */
  background: #fff; 
  /* textPrimary */
  border: 1px solid #000;
  box-shadow: 0 2px 0 0 rgba(29,32,43,0.15), 0 4px 12px -3px rgba(29,32,43,0.15);
  border-radius: 5px;
  animation: sup-in 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
  will-change: transform, opacity;
  user-select: none;
}

.ej-light-fn-tune {
  display: flex !important;
  align-items: center;
  white-space: nowrap;
  padding: 2px 15px 2px 2px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 100ms ease;
  will-change: background-color;
  user-select: none;
}

.ej-light-fn-tune:hover:not(.ej-light-fn-tune--disabled) {
  /* secondaryBackground */
  background: rgb(247, 247, 247);
}

.ej-light-fn-tune__icon {
  display: flex !important;
  width: 26px;
  height: 26px;
  align-items: center;
  justify-content: center;
  margin-right: 4px;
}

.ej-light-fn-tune__label {
  font-size: 14px;
  line-height: 22px;
}

.ej-light-fn-tune--disabled {
  opacity: 0.7;
  cursor: default;
}

.ej-light-fn-wrapper {
  position: relative;
}

.ej-light-fn-popover {
  position: absolute;
  z-index: 3;
  box-sizing: border-box;
  margin-top: 10px;
  overflow: hidden;
  width: 600px;
  overflow-y: auto;
  padding: 9px 20px 16px 20px;
  border-radius: 6px;
  box-shadow: 0 3px 15px -3px rgba(13, 20, 33, 0.13);
  /* secondaryBackground */
  border: 1px solid #EAEAEA;
  /* background */
  background-color: #fff;
  display: none !important;
  will-change: opacity, transform;
}

.ej-light-fn-popover--opened {
  display: flex !important;
  flex-flow: column nowrap;
  justify-content: space-between;
  animation: fn-popover-in 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
}

.ej-light-fn-popover::before {
  content: '';
  position: absolute;
  top: 9px;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 20px;
  pointer-events: none;
}

.ej-light-fn-popover::after {
  content: '';
  position: absolute;
  bottom: 56px;
  width: 100%;
  height: 20px;
  pointer-events: none;
}

.ej-light-fn-popover__textarea {
  position: relative;
  min-height: 202px;
  max-height: 425px;
  align-self: stretch;
  overflow: auto;
  padding: 8px 0;
  outline: none;
  font-size: 15px;
  letter-spacing: -0.04px;
  line-height: 26px;
}

.ej-light-fn-popover__textarea:empty::before {
  content: attr(data-placeholder);
  /* selectedBackground */
  color: #BDBDBD;
}

.ej-light-fn-popover__buttons {
  display: flex !important;
  flex-flow: row nowrap;
  align-items: center;
  padding-top: 10px;
}

.ej-light-fn-popover__button {
  padding: 8px 12px;
  border-radius: 8px;
  box-shadow: 0 2px 2px 0 rgba(18, 30, 57, 0.04);
  /* secondaryBackground */
  border: solid 1px rgb(247, 247, 247);
  /* background */
  background-color: #fff;
  /* textPrimary */
  color: #000;
  font-size: 14px;
  font-weight: 500;
  line-height: 1em;
  cursor: pointer;
}

.ej-light-fn-popover__button--right {
  margin-left: auto;
}

.ej-light-fn-popover__hint {
  margin-left: 15px;
  /* selectedBackground */
  color: #BDBDBD;
  font-size: 13px;
  letter-spacing: -0.08px;
  text-align: center;
  user-select: none;
}

/* ------------------------------------------------------------------------------------------------------------ */

/* Dark mode styles */

.ej-dark-fn-sup {
    display: inline-flex ;
    align-items: center;
    justify-content: center;
    margin: 0 7px 0 5px;
    padding: 3px 6px;
    font-size: 12px;
    line-height: 12px;
    font-weight: 600;
    cursor: pointer;
    /* textPrimary */
    color: #fff;
    /* background */
    background: #37474F; 
    /* textPrimary */
    border: 1px solid #fff;
    box-shadow: 0 2px 0 0 rgba(29,32,43,0.15), 0 4px 12px -3px rgba(29,32,43,0.15);
    border-radius: 5px;
    animation: sup-in 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
    will-change: transform, opacity;
    user-select: none;
  }
  
  .ej-dark-fn-tune {
    display: flex !important;
    align-items: center;
    white-space: nowrap;
    padding: 2px 15px 2px 2px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 100ms ease;
    will-change: background-color;
    user-select: none;
  }
  
  .ej-dark-fn-tune:hover:not(.ej-dark-fn-tune--disabled) {
    /* secondaryBackground */
    background: rgb(32, 32, 32);
  }
  
  .ej-dark-fn-tune__icon {
    display: flex !important;
    width: 26px;
    height: 26px;
    align-items: center;
    justify-content: center;
    margin-right: 4px;
  }
  
  .ej-dark-fn-tune__label {
    font-size: 14px;
    line-height: 22px;
  }
  
  .ej-dark-fn-tune--disabled {
    opacity: 0.7;
    cursor: default;
  }
  
  .ej-dark-fn-wrapper {
    position: relative;
  }
  
  .ej-dark-fn-popover {
    position: absolute;
    z-index: 3;
    box-sizing: border-box;
    margin-top: 10px;
    overflow: hidden;
    width: 600px;
    overflow-y: auto;
    padding: 9px 20px 16px 20px;
    border-radius: 6px;
    box-shadow: 0 3px 15px -3px rgba(13, 20, 33, 0.13);
    /* secondaryBackground */
    border: 1px solid rgb(32, 32, 32);
    /* background */
    background-color: rgb(25, 25, 25);
    display: none !important;
    will-change: opacity, transform;
  }
  
  .ej-dark-fn-popover--opened {
    display: flex !important;
    flex-flow: column nowrap;
    justify-content: space-between;
    animation: fn-popover-in 150ms cubic-bezier(0.215, 0.61, 0.355, 1);
  }
  
  .ej-dark-fn-popover::before {
    content: '';
    position: absolute;
    top: 9px;
    left: 0;
    z-index: 1;
    width: 100%;
    height: 20px;
    pointer-events: none;
  }
  
  .ej-dark-fn-popover::after {
    content: '';
    position: absolute;
    bottom: 56px;
    width: 100%;
    height: 20px;
    pointer-events: none;
  }
  
  .ej-dark-fn-popover__textarea {
    position: relative;
    min-height: 202px;
    max-height: 425px;
    align-self: stretch;
    overflow: auto;
    padding: 8px 0;
    outline: none;
    font-size: 15px;
    letter-spacing: -0.04px;
    line-height: 26px;
  }
  
  .ej-dark-fn-popover__textarea:empty::before {
    content: attr(data-placeholder);
    /* selectedBackground */
    color: #37474F;
  }
  
  .ej-dark-fn-popover__buttons {
    display: flex !important;
    flex-flow: row nowrap;
    align-items: center;
    padding-top: 10px;
  }
  
  .ej-dark-fn-popover__button {
    padding: 8px 12px;
    border-radius: 8px;
    box-shadow: 0 2px 2px 0 rgba(18, 30, 57, 0.04);
    /* secondaryBackground */
    border: solid 1px rgb(32, 32, 32);
    /* background */
    background-color: rgb(25, 25, 25);
    /* textPrimary */
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    line-height: 1em;
    cursor: pointer;
  }
  
  .ej-dark-fn-popover__button--right {
    margin-left: auto;
  }
  
  .ej-dark-fn-popover__hint {
    margin-left: 15px;
    /* selectedBackground */
    color: #37474F;
    font-size: 13px;
    letter-spacing: -0.08px;
    text-align: center;
    user-select: none;
  }

/* ------------------------------------------------------------------------------------------------------------ */

/**
 * Hack for fake cursor
 */
 .codex-editor__fake-cursor {
    display: inline-block !important;
    height: 1.15em;
    margin-bottom: -3px;
    margin-right: -1px;
    /* textPrimary */
    border-left: 1px solid #000;
    animation: blink 1s steps(2, start) infinite;
  }
  
  @keyframes blink {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
  
  @keyframes sup-in {
    from {
      transform: translateY(-5px); 
      opacity: 0;
   }
  }

@keyframes fn-popover-in {
    from {
      opacity: 0;
      transform: translateY(8px);
    }
    70% {
      opacity: 1;
      transform: translateY(-2px);
    }
    to {
      transform: translateY(0);
    }
  }
  