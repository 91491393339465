#card-element-light {
  border-radius: 4px;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  width: 100%;
  background: rgb(247, 247, 247);
  box-sizing: border-box;
}

#card-element-dark {
  border-radius: 4px;
  padding: 12px;
  border: 1px solid rgba(50, 50, 93, 0.1);
  max-height: 44px;
  width: 100%;
  background: rgb(25, 25, 25);
  box-sizing: border-box;
}