.w-md-editor-text-pre > code,
.w-md-editor-text-input {
  font-size: 14px !important;
  line-height: 24px !important;
  font-family: "Roboto-Medium" !important;
}

.ce-block--selected .ce-block__content,
.ce-inline-toolbar,
.codex-editor--narrow .ce-toolbox,
.ce-conversion-toolbar,
.ce-settings,
.ce-settings__button,
.ce-toolbar__settings-btn,
.cdx-button,
.ce-popover,
.ce-toolbar__plus:hover {
  color: inherit;
}

.ce-inline-tool,
.ce-conversion-toolbar__label,
.ce-toolbox__button,
.cdx-settings-button,
.ce-toolbar__plus {
  color: inherit;
}

.cdx-settings-button:hover,
.ce-settings__button:hover,
.ce-toolbox__button--active,
.ce-toolbox__button:hover,
.cdx-button:hover,
.ce-inline-toolbar__dropdown:hover,
.ce-inline-tool:hover,
.ce-popover__item:hover,
.ce-popover-item:hover,
.ce-toolbar__settings-btn:hover {
  color: inherit;
}

.ce-paragraph {
  display: block !important
}

.ce-header {
  display: block !important
}

.cdx-notify--error {
  background: #fb5d5d !important;
}

.codex-editor--narrow .ce-toolbar__actions {
  right: 101% !important;
}

.ce-toolbar__content {
  max-width: 92%;
}

.ce-block__content {
  max-width: 92%;
}

.ce-popover__item-icon {
  background: transparent;
}

.cdx-search-field {
  display: none;
}

.ce-popover {
  box-shadow:1px 8px 16px 2px rgba(13,20,33,.60);
  border-color: gray;
  left: 0;
}

.ce-popover__items {
  margin-top: 1px !important;
}

.ce-popover__custom-content {
  margin-top: 0px !important;
}

.ce-popover__item {
  margin-top: 2px;
  margin-bottom: 2px;
}

.ce-popover-item {
  margin-top: 2px;
  margin-bottom: 2px;
}

.ce-popover__item:hover {
  background: rgba(25, 118, 210, 0.16) !important;
}

.ce-popover-item:hover {
  background: rgba(25, 118, 210, 0.16) !important;
}

.editor-glass {
  display: flex;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  height: 100vh;
}

.ce-toolbar__plus {
  display: block;
}

.ce-inline-toolbar__dropdown {
  display: none;
}

/* OnlyOriginal view mode styles ------------------------------------------------------------------------------------*/

.editor-OnlyOriginal-view-mode .ce-block__content {
  display: block !important;
}

.editor-OnlyOriginal-view-mode .ce-translation-block__content {
  display: none !important;
}


/* ------------------------------------------------------------------------------------------------------------ */

/* OnlyTranslation view mode styles ------------------------------------------------------------------------------------*/

.editor-OnlyTranslation-view-mode .ce-block__content {
  display: none !important;
}

.editor-OnlyTranslation-view-mode .ce-translation-block__content {
  display: block !important;
}

/* ------------------------------------------------------------------------------------------------------------ */

/* Side view mode styles ----------------------------------------------------------------------------------------*/

.editor-Side-view-mode .ce-block div {
  display: block;
  flex: 1;
}

#editor.editor-Side-view-mode.translation-removed .ce-translation-block__content {
  display: none;
}


#editor.editor-Side-view-mode:not(.translation-block-visible):not(.button-pressed) .ce-block {
  padding-left: 4%;
}
/* Set default styles for Side view */
.editor-Side-view-mode .ce-block__content {
  display: block;
  flex: 1;
}



.editor-Side-view-mode .ce-block {
  box-sizing: border-box;
}

.editor-Side-view-mode .ej-light-fn-wrapper > div,
.editor-Side-view-mode .ej-dark-fn-wrapper > div {
  display: flex;
}

.translation-block-visible {
  position: sticky;
}

.editor-Side-view-mode .ej-light-fn-wrapper > div {
  display: flex;
}
.editor-Side-view-mode .ej-dark-fn-wrapper > div {
  display: flex;
}
/* ------------------------------------------------------------------------------------------------------------ */

/* Bottom view mode styles */

.editor-Bottom-view-mode {
  display: block;
}


#editor.editor-Bottom-view-mode.translation-removed .ce-translation-block__content {
  display: none;
}



/* ------------------------------------------------------------------------------------------------------------ */

/* Light mode styles */
.editor-light-mode .ce-popover-item__icon {
  color: #000;
  background: transparent;
}

.editor-light-mode .ce-popover-item {
  color: #000000;
}

.editor-light-mode .ce-block--selected .ce-block__content {
  background: #BDBDBD;
}

.editor-light-mode ::selection  {
  background: #BDBDBD;
}

.editor-light-mode .ce-popover {
  background: #BDBDBD;
}

.editor-light-mode .ce-toolbar__settings-btn {
  background: #BDBDBD;
}

.editor-light-mode .ce-toolbar__settings-btn:hover {
  background: #dadada;
}

.editor-dark-mode .ce-toolbar__plus {
  background: #BDBDBD;
}

.editor-dark-mode .ce-toolbar__plus:hover {
  background: #dadada;
}
  
.editor-light-mode .ce-inline-tool:hover {
  background-color: #dadada;
  color: inherit;
}

.editor-light-mode .ce-popover__item:hover {
  background-color: #dadada;
  color: inherit;
}

.editor-light-mode .ce-popover-item:hover {
  background-color: #dadada;
  color: inherit;
}

.editor-light-mode .ce-inline-toolbar {
  background: #BDBDBD;
  color: inherit;
}

.editor-light-mode .cdx-notify__cross::after,
.cdx-notify__cross::before {
  background: #BDBDBD;
  color: inherit;
}

.editor-light-mode .cdx-settings-button:hover,
.ce-settings__button:hover,
.ce-toolbox__button--active,
.ce-toolbox__button:hover,
.cdx-button:hover,
.ce-inline-toolbar__dropdown:hover,
.ce-inline-tool:hover,
.ce-popover__item:hover,
.ce-popover-item:hover,
.ce-toolbar__settings-btn:hover {
  background-color: #dadada;
  color: inherit;
}

.cdx-text-variant__toggler {
  width: 100%;
  justify-content: flex-start;
}

/* ------------------------------------------------------------------------------------------------------------ */

/* Dark mode styles */

.editor-dark-mode .ce-popover-item__icon {
  color: #fff;
  background: transparent;
}

.editor-dark-mode .ce-popover-item {
  color: #ffffff;
}

.editor-dark-mode .ce-block--selected .ce-block__content {
  background: #37474F;
}

.editor-dark-mode ::selection  {
  background: #37474F;
}

.editor-dark-mode .ce-popover {
  background: #37474F;
}

.editor-dark-mode .ce-toolbar__settings-btn {
  background: #37474F;
}

.editor-dark-mode .ce-toolbar__settings-btn:hover {
  background: #5b6d76;
}

.editor-dark-mode .ce-toolbar__plus {
  background: #37474F00;
}

.editor-dark-mode .ce-toolbar__plus:hover {
  background: #5b6d76;
}
  
.editor-dark-mode .ce-inline-tool:hover {
  background-color: #5b6d76;
  color: inherit;
}

.editor-dark-mode .ce-popover__item:hover {
  background-color: #5b6d76;
  color: inherit;
}

.editor-dark-mode .ce-popover-item:hover {
  background-color: #5b6d76;
  color: inherit;
}

.editor-dark-mode .ce-inline-toolbar {
  background: #37474F;
  color: inherit;
}

.editor-dark-mode .cdx-notify__cross::after,
.cdx-notify__cross::before {
  background: #37474F;
  color: inherit;
}

.editor-dark-mode .cdx-settings-button:hover,
.ce-settings__button:hover,
.ce-toolbox__button--active,
.ce-toolbox__button:hover,
.cdx-button:hover,
.ce-inline-toolbar__dropdown:hover,
.ce-inline-tool:hover,
.ce-popover__item:hover,
.ce-popover-item:hover,
.ce-toolbar__settings-btn:hover {
  background-color: #5b6d76;
  color: inherit;
}

/* ------------------------------------------------------------------------------------------------------------ */
