@import "components/index.css";
  
  @font-face {
    font-family: "Roboto-Medium";
    src: local("Roboto-Medium"),
     url("../fonts/Roboto-Medium.ttf") format("truetype");
  }

  @font-face {
   font-family: "Roboto-Medium";
   src: local("Roboto-Medium"),
    url("../fonts/Roboto-Medium.ttf") format("truetype");
 }
  
  @font-face {
     font-family: "Montserrat-Bold";
    src: local("Montserrat-Bold"),
     url("../fonts/Montserrat-Bold.ttf") format("truetype");
  }